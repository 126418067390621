import { NotificationContainer, NotificationManager } from "react-notifications";
import generateAppleLink from "../AppleCalendar.ts";
import ButtonPrimary from "../basePageComponents/buttons/ButtonPrimary";
import ButtonSecondary from "../basePageComponents/buttons/ButtonSecondary";
import { CategoryImages } from "../basePageComponents/images/CategoryImage";
import { ContactImage } from "../basePageComponents/images/ContactImage";
import PageCart from "../basePageComponents/PageCart";
import PageHeader from "../basePageComponents/PageHeader";
import generateGoogleCalendarURL from "../GoogleCalendar";
import UnassignMeConfirm from './UnassignMeConfirm';
import t from '../translations/translate.ts';
import './TaskDetails.css';
import { useState } from 'react';

export default function TaskDetailsMyTask({ onClose = () => null, task = {}, link = '#MY_TASK_LINK', closeAndUpdate = () => null }) {
    const [displayDialog, setDisplayDialog] = useState(false);
    //
    const calendarLink = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/Macintosh/i)) ?
        generateAppleLink(task.title, task.type === "no_time" ? new Date() : task.task_date,
            task.type === "no_time" ? new Date() : task.task_date,
            task.description, 'Enola App Task', task.id, localStorage.login_familyToken) :
        generateGoogleCalendarURL(task.title,
            task.type === "no_time" ? new Date() : task.task_date, task.type === "no_time" ? new Date() : task.task_date,
            task.description, 'Enola App Task', task.id, localStorage.login_familyToken);
    //
    const taskPhone = task.creator.formatted_phone_number.replaceAll('-', '').replaceAll(' ', '');
    const taskSmsLink = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/Macintosh/i)) ?
        "sms:" + taskPhone :
        "sms:" + taskPhone + "?body=" + encodeURIComponent(
            `Hello, ${task.creator.full_name}! I have some questions about task "${task.title}"`);
    const taskWhatsAppLink = "https://wa.me/" + taskPhone.replaceAll("+", "")
        + "/?text=" + encodeURIComponent(
            `Hello, ${task.creator.full_name}! I have some questions about task "${task.title}"`);
    //
    if (displayDialog) {
        return <UnassignMeConfirm
            task={task}
            onConfirm={async (txt) => {
                setDisplayDialog(false);
                console.log("will be cancelled");
                //
                const takeLink = link + '/unassign_me';
                console.log(takeLink);
                //
                await fetch(takeLink, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ reason: txt }),
                });
                NotificationManager.error(
                    `Task "${task.title}" unassigned successfully`,
                    "Task unassigned",
                    2500);
                setTimeout(() => closeAndUpdate(), 100);
                //
            }}
            onCancel={() => setDisplayDialog(false)} />
    }
    //
    return <div className="full_window base_background">
        <PageHeader onBackButtonClick={onClose} pageTitle={task.title} />
        <div style={{
            backgroundColor: '',
            position: 'relative',
            height: 'calc(100vh - 220px)',
            overflowY: 'scroll',
            overflowX: 'hidden',
            paddingBottom: 20
        }} >
            <div style={{
                margin: 'auto', width: 'calc(100% - 50px)',
                marginTop: 20, marginBottom: 20,
                borderRadius: 24, overflow: 'hidden'
            }}>
                <img style={{ padding: 0, margin: 0, margin: 'auto', borderRadius: 24 }} width={'100%'} height={148} src={CategoryImages[task.category]} alt='img' />
            </div>


            {task.type === "no_time" ? <>
                <PageCart content={<span>
                    <span className="_black_bold">Any time</span>
                    <br />
                    <br />
                    <a target="_blank" className="_green" href={calendarLink}>
                        <div style={{ display: 'inline-block' }}>
                            <img src={ContactImage.Calendar} alt="add" />
                        </div>
                        <div style={{ display: 'inline-block', lineHeight: 1 }}>
                            &nbsp;&nbsp;
                        </div>
                        <div style={{ display: 'inline-block', lineHeight: 1, verticalAlign: 'top', paddingTop: 4 }}>
                            {t('addCalendar')}
                        </div>
                    </a>
                    <br />
                </span>
                } />
            </> : <>
                <PageCart content={<span>
                    <span className="_black_bold">Start at:</span><br />
                    <span className="_black">{task.local_start_at}</span>
                    <br />
                    <span className="_black_bold">End at:</span><br />
                    <span className="_black">{task.local_end_at}</span>
                    <br /><br />
                    <a target="_blank" className="_green" href={calendarLink}>
                        <div style={{ display: 'inline-block' }}>
                            <img src={ContactImage.Calendar} alt="add" />
                        </div>
                        <div style={{ display: 'inline-block', lineHeight: 1 }}>
                            &nbsp;&nbsp;
                        </div>
                        <div style={{ display: 'inline-block', lineHeight: 1, verticalAlign: 'top', paddingTop: 4 }}>
                            {t('addCalendar')}
                        </div>
                    </a>
                </span>} />
            </>}

            <PageCart content={<span>
                <span className="_black_bold">{t('description')}</span><br />
                <span className="_black">{task.description}</span>
            </span>} />

            <PageCart content={<span>
                <span className="_grey_16">{t('forMoreDetails')}</span><br />
                <span style={{ lineHeight: 2, color: '#313E44' }} className="_black_bold">{task.creator.full_name}</span><br />
                <a target="_blank" style={{ paddingTop: 6, paddingBottom: 6, lineHeight: 2 }} href={`tel:${taskPhone}`} className="_green">
                    <img src={ContactImage.Phone} alt="contact" />
                    &nbsp; {taskPhone}
                </a><br />
                <a target="_blank" href={taskSmsLink} style={{ paddingTop: 6, paddingBottom: 6, lineHeight: 2 }} className="_green">
                    <img src={ContactImage.SMS} alt="contact" />
                    &nbsp; {t('sendMessage')}
                </a><br />
                <a target="_blank" style={{ paddingTop: 6, paddingBottom: 6, lineHeight: 2 }} href={taskWhatsAppLink} className="_green">
                    <img src={ContactImage.WhatsApp} alt="contact" />
                    &nbsp; {t('sendWhatsApp')}
                </a><br />
            </span>} />

        </div>
        <div style={{ backgroundColor: 'white', height: 220, paddingTop: 20 }}>
            <ButtonPrimary onClick={async () => {
                const takeLink = link + '/complete';
                console.log(takeLink);
                await fetch(takeLink, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({}),
                });
                NotificationManager.success(`Task "${task.title}" completed successfully`, "Task completed", 2000);
                setTimeout(() => closeAndUpdate(), 100);
            }} width="calc(100% - 25px)" txt={t('done')} />
            <ButtonSecondary width="calc(100% - 25px)" onClick={
                async () => {
                    setDisplayDialog(true);
                    /*
                    const takeLink = link + '/unassign_me';
                    console.log(takeLink);
                    //
                    await fetch(takeLink, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ reason: "QA test reason" }),
                    });
                    NotificationManager.error(
                        `Task "${task.title}" unassigned successfully`,
                        "Task unassigned",
                        2500);
                    setTimeout(() => closeAndUpdate(), 100);
                    */
                }
            } txt="Withdraw" />
        </div>
        <NotificationContainer />
    </div>
}